export function transformRDPRecording(config) {
  return {
    id: config.id,
    fileName: config.fileName,
    status: config.status,
    createdAt: config.createdTime,
    converted: config.converted,
    m4vFileName: config.m4vFileName,
    m4vFileSize: config.m4vFileSize,
    playUrl: config.playUrl,
  }
}

export function transformRdpConfiguration(config) {
  return {
    enableRdp: config.enableRdp,
    onlyAllowDepartmentManagerToTakeRDP:
      config.onlyAllowDepartmentManagerToTakeRDP,
    enableVideoRecording: config.enableVideoRecording,
    sessionIndication: config.sessionIndication,
    connectionType: config.connectionType,
    rdpConfigType: config.rdpConfigType,
    repeaterHost: config.repeaterHost,
    repeaterPort: config.repeaterPort,
    vncPassword: config.vncPassword,
    vncPort: config.vncPort,
    userConformationRequired: config.userConformationRequired,
    refreshCycle: config.refreshCycle,
    enableStun: config.enableStun,
    stunServerHost: config.stunServerHost,
    turnServerHost: config.turnServerHost,
    userName: config.userName,
    password: config.password,
    meshServerIp: config.meshServerIp,
    meshServerFqdn: config.meshServerFqdn,
    meshRdpConnectionType: config.meshRdpConnectionType,
    sshPort: config.sshPort,
    sshKey: config.sshKey,
    sshPassphrase: config.sshPassphrase,
    rdpAuthType: config.rdpAuthType,
  }
}

export function transformRdpConfigurationForServer(config) {
  return {
    enableRdp: config.enableRdp,
    onlyAllowDepartmentManagerToTakeRDP:
      config.onlyAllowDepartmentManagerToTakeRDP,
    enableVideoRecording: config.enableVideoRecording,
    sessionIndication: config.sessionIndication,
    connectionType: config.connectionType,
    rdpConfigType: config.rdpConfigType,
    repeaterHost: config.repeaterHost,
    repeaterPort: config.repeaterPort,
    vncPassword: config.vncPassword,
    vncPort: config.vncPort,
    userConformationRequired: config.userConformationRequired,
    refreshCycle: config.refreshCycle,
    enableStun: config.enableStun,
    stunServerHost: config.stunServerHost,
    turnServerHost: config.turnServerHost,
    userName: config.userName,
    password: config.password,
    meshServerIp: config.meshServerIp,
    meshServerFqdn: config.meshServerFqdn,
    meshRdpConnectionType: config.meshRdpConnectionType,
    sshPort: config.sshPort,
    sshKey: config.sshKey,
    sshPassphrase: config.sshPassphrase,
    rdpAuthType: config.rdpAuthType,
  }
}
// RDP agent
export function transformRdpAgents(rdpAgent) {
  return {
    id: rdpAgent.id,
    name: rdpAgent.name,
    hostName: rdpAgent.hostName,
    ipAddress: rdpAgent.ipAddress,
    uuid: rdpAgent.uuid,
    agentVersion: rdpAgent.agentVersion,
    lastRefreshCallTime: rdpAgent.lastRefreshCallTime,
    refreshCycle: rdpAgent.refreshCycle,
    assetId: rdpAgent.assetId,
    repeaterId: rdpAgent.repeaterId,
    osName: rdpAgent.osName,
    active: rdpAgent.active,
    archived: rdpAgent.removed,
    architecture: rdpAgent.architecture,
  }
}

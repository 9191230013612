import Constants from '@constants'

export function transformTaskType(taskType) {
  return {
    id: taskType.id,
    name: taskType.name,
    systemName: taskType.systemName,
    canDelete: taskType.deleteableOob,
    canEdit: taskType.updatebleOob,
    order: taskType.objOrder,
  }
}

export function transformTaskTypeForServer(taskType) {
  return {
    name: taskType.name,
  }
}

export function transformTask(task) {
  return {
    id: task.id,
    name: task.name,
    subject: task.subject,
    moduleName: task.refModel,
    ...(task.refId ? { parentId: task.refId } : {}),
    ...(task.reference ? { reference: task.reference } : {}),
    statusId: task.statusId,
    groupId: task.groupId,
    technicianId: task.technicianId,
    description: task.description,
    fileAttachments: task.fileAttachments,
    taskTypeId: task.taskTypeId,
    priorityId: task.priorityId,
    estimatedTime: task.estimatedTime,
    estimatedTimeUnit: task.estimatedTimeUnit || 'hours',
    timeRange: {
      startTime: task.startTime,
      endTime: task.endTime,
    },
    startTime: task.startTime,
    endTime: task.endTime,
    stage: task.taskDependencyStage,
    order: task.objOrder,
    notifyBeforeHours: task.notifyBeforeHours,
    notifyBeforeHoursTimeUnit: task.notifyBeforeHoursTimeUnit || 'hours',
    createdAt: task.createdTime,
    owner: task.createdById,
    closedAt: task.lastClosedTime,
    archived: task.removed,
    ...(task.refModel === Constants.PROJECT
      ? {
          duration: task.duration,
          completion: task.completion,
          predecessors: task.predecessors,
          successors: task.successors,
        }
      : {}),
    fieldValueDetails: task.fieldValueDetails,
    ...(task.fieldValueDetails || {}),
    referenceArchived: task.referenceArchived,
  }
}

export function transformTaskForServer(task) {
  const transformedProps = ['fieldValueDetails', 'stage', 'duration']
  const obj = {
    ...(task.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...task.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(task).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = task[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: task[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = task[paramName]
    }
  })
  if ('description' in task) {
    obj.description = task.description || ''
  }
  if ('stage' in task) {
    obj.taskDependencyStage = task.stage
  }
  if ('duration' in task) {
    obj.duration = +task.duration
  }
  return obj
}

// export function transformTaskForServer(task) {
//   const obj = {
//     ...(task.fieldValueDetails
//       ? {
//           fieldValueDetails: {
//             ...task.fieldValueDetails,
//           },
//         }
//       : {}),
//   }
//   Object.keys(task).forEach((paramName) => {
//     if (/^\d+$/.test(paramName)) {
//       if (obj.fieldValueDetails) {
//         obj.fieldValueDetails[paramName] = task[paramName]
//       } else {
//         obj.fieldValueDetails = {
//           [paramName]: task[paramName],
//         }
//       }
//     }
//   })
//   return {
//     name: task.name,
//     subject: task.subject,
//     statusId: task.statusId,
//     groupId: task.groupId,
//     technicianId: task.technicianId,
//     ...('description' in task ? { description: task.description || '' } : {}),
//     ...(task.fileAttachments
//       ? {
//           fileAttachments: (task.fileAttachments || []).map(
//             transformAttachmentForServer
//           ),
//         }
//       : {}),
//     taskDependencyStage: task.stage,
//     estimatedTime: task.estimatedTime,
//     estimatedTimeUnit: task.estimatedTimeUnit,
//     // objOrder: task.order, // removed in new fixed
//     taskTypeId: task.taskTypeId,
//     priorityId: task.priorityId,
//     startTime: task.startTime || (task.timeRange || {}).startTime,
//     endTime: task.endTime || (task.timeRange || {}).endTime,
//     notifyBeforeHours: task.notifyBeforeHours,
//     notifyBeforeHoursTimeUnit: task.notifyBeforeHoursTimeUnit,
//     ...(task.moduleName === Constants.PROJECT
//       ? {
//           duration: Number(task.duration),
//           completion: task.completion,
//           predecessors: task.predecessors,
//           successors: task.successors,
//         }
//       : {}),
//     ...obj,
//   }
// }

export function tranformMovedTaskForServer(task) {
  return {
    taskId: task.id,
    newObjOrder: task.newOrder,
    newStage: task.stage,
  }
}

export const defaultTask = {
  subject: undefined,
  statusId: undefined,
  groupId: undefined,
  technicianId: 0,
  description: undefined,
  fileAttachments: undefined,
  taskTypeId: 1,
  priorityId: 1,
  notifyBeforeHours: 0,
  estimatedTimeUnit: 'hours',
  notifyBeforeHoursTimeUnit: 'hours',
  timeRange: {
    startTime: undefined,
    endTime: undefined,
  },
}
export const defaultTaskForWorkflow = {
  technicianId: 0,
  taskTypeId: 1,
  priorityId: 1,
  notifyBeforeHours: 0,
  estimatedTimeUnit: 'hours',
  notifyBeforeHoursTimeUnit: 'hours',
}

import { generateId } from '@utils/id'
export function transformSsoConfig(ssoConfig) {
  return {
    id: ssoConfig.id,
    name: ssoConfig.name,
    ssoEnabled: ssoConfig.ssoEnabled,
    isPrimary: ssoConfig.isDefault,
    ssoProvider: ssoConfig.ssoProvider,
    enforceSsoLoginOnly: ssoConfig.enforceSsoLoginOnly,
    autoCreateUser: ssoConfig.autoCreateUser,
    excludedTechnincians: ssoConfig.excludedTechnincians,
    idpEntityId: ssoConfig.idpEntityId,
    idpLoginUrl: ssoConfig.idpLoginUrl,
    idpLogoutUrl: ssoConfig.idpLogoutUrl,
    idpFingerprint: ssoConfig.idpFingerprint,
    spEntityId: ssoConfig.spEntityId,
    spAssertionUrl: ssoConfig.spAssertionUrl,
    spSingleLogoutUrl: ssoConfig.spSingleLogoutUrl,
    attachments: ssoConfig.attachments,
    spPublicKeyFiles: ssoConfig.spPublicKeyFiles,
    spPrivateKeyFiles: ssoConfig.spPrivateKeyFiles,
    canDelete: ssoConfig.deleteableOob,
    canEdit: ssoConfig.updatebleOob,
    userInputRequired: ssoConfig.userInputRequired,
    isMspPortal: ssoConfig.mspPortal,
    archived: ssoConfig.removed,
    attributes: (ssoConfig.attributes || []).map((m) => ({
      ...m,
      guid: generateId(),
    })),
  }
}

export function transformSsoConfigForServer(ssoConfig) {
  return {
    name: ssoConfig.name,
    ssoEnabled: ssoConfig.ssoEnabled,
    ssoProvider: !ssoConfig.ssoProvider ? null : ssoConfig.ssoProvider,
    isDefault:
      'ssoEnabled' in ssoConfig && !ssoConfig.ssoEnabled
        ? false
        : ssoConfig.isPrimary,
    // enforceSsoLoginOnly: ssoConfig.enforceSsoLoginOnly,
    autoCreateUser: ssoConfig.autoCreateUser,
    // excludedTechnincians: ssoConfig.excludedTechnincians,
    idpEntityId: ssoConfig.idpEntityId,
    idpLoginUrl: ssoConfig.idpLoginUrl,
    idpLogoutUrl: ssoConfig.idpLogoutUrl,
    idpFingerprint: ssoConfig.idpFingerprint,
    spEntityId: ssoConfig.spEntityId,
    spAssertionUrl: ssoConfig.spAssertionUrl,
    spSingleLogoutUrl: ssoConfig.spSingleLogoutUrl,
    attachments: ssoConfig.attachments,
    spPublicKeyFiles: ssoConfig.spPublicKeyFiles,
    spPrivateKeyFiles: ssoConfig.spPrivateKeyFiles,
    attributes: ssoConfig.attributes,
  }
}

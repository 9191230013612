import { transformUserForVuex } from './user'
import { transformEscalationLevel } from './escalation-level'
// import { transformWatcherForServer, transformWatcher } from './watcher'

export const transformLinkAssetIds = (request) => {
  return (request.linkAssetIds || []).map((a) => `${a.assetId}:${a.assetModel}`)
}
export const transformLinkAssetIdsForServer = (request) => {
  if (!(request.linkAssetIds || []).length) {
    return []
  }
  return (request.linkAssetIds || []).map((a) => ({
    assetId: +String(a).split(':')[0],
    assetModel: String(a).split(':')[1],
  }))
}

export function transformTicketForList(request) {
  return {
    id: request.id,
    name: request.name,
    subject: request.displayName ? request.displayName : request.subject,
    description: request.description,
    createdAt: request.createdTime,
    ticketAge: request.createdTime,
    updatedAt: request.updatedTime,
    createdBy: request.createdById,
    archived: request.removed,
    requesterId: request.requesterId,
    requester: request.requesterName,
    requesterData: transformUserForVuex(
      {
        id: request.requesterId,
        name: request.requesterName,
        email: request.requesterEmail,
      },
      true
    ),
    groupId: request.groupId,
    statusId: request.statusId,
    priorityId: request.priorityId,
    technicianId: request.technicianId,
    lastClosedTime: request.lastClosedTime,
    lastResolvedTime: request.lastResolvedTime,
    dueBy: request.dueBy,
    oldDueBy: request.oldDueBy,
    responseDue: request.responseDue,
    oldResponseDue: request.oldResponseDue,
    relationId: request.relationId,
    relationType: request.relationType,
    vipRequest: request.vipRequest,
    hasSecondaryTickets: request.hasSecondaryTickets,
    olaDueBy: request.olaDueBy,
    oldOlaDueBy: request.oldOlaDueBy,
    closedBy: request.closedBy,
    resolvedBy: request.resolvedBy,
    companyId: request.companyId,
    tags: request.tags,
  }
}

export function transformTicketForDetail(request) {
  const listProps = transformTicketForList(request)
  return {
    ...listProps,
    businessServiceId: request.businessServiceId,
    natureOfProblem: request.natureOfProblem,
    knownError: request.knownError ? 'true' : 'false',
    identifiedAsProblem: request.isIdentifiedAsProblem,
    purchaseRequest: request.purchaseRequest,
    fieldValueDetails: request.fieldValueDetails || {},
    urgencyId: request.urgencyId,
    impactId: request.impactId,
    description: (request.description || '').replace(/\n/g, '<br />'),
    supportLevel: request.supportLevel,
    sourceId: request.sourceId,
    messengerConfigId: request.messengerConfigId,
    locationId: request.locationId,
    vendorId: request.vendorId,
    escalationLevel: transformEscalationLevel(request.resolutionDueLevel),
    resolvedAt: request.lastResolvedTime,
    showIntegrationTab: request.showIntegrationTab,
    categoryId: request.categoryId,
    suggestedCategoryId: request.suggestedCategoryId,
    suggestedGroupId: request.suggestedGroupId,
    closedAt: request.lastClosedTime,
    incomingMailbox: request.emailReadConfigEmail,
    ccEmailSet: request.ccEmailSet,
    estimatedTime: request.estimatedTime,
    spam: request.spam,
    // watcher: transformWatcher(request.watcher || {}),
    fileAttachments: request.fileAttachments,
    templateId: request.templateId,
    requestType: request.requestType,
    serviceCatalogId: request.serviceCatalogId,
    departmentId: request.departmentId,
    reopenCount: request.reopened,
    slaViolated: request.slaViolated,
    olaViolated: request.olaViolated,
    responseDue: request.responseDue,
    oldResponseDue: request.oldResponseDue,
    approvalStatus: request.approvalStatus,
    resolutionTime: request.totalResolutionTime,
    changeReviewer: request.changeReviewer,
    changeManager: request.changeManager,
    changeImplementor: request.changeImplementor,
    changeScheduleDetected: request.changeScheduleDetected,
    riskTypeId: request.riskTypeId,
    changeTypeId: request.changeTypeId,
    releaseTypeId: request.releaseTypeId,
    releaseEngineer: request.releaseEngineer,
    releaseManager: request.releaseManager,
    qaManager: request.qaManager,
    violatedSlaId: request.violatedSlaId,
    releaseReviewer: request.releaseReviewer,
    affectedServices: request.affectedServices || [],
    reasonType: request.reasonType,
    targetEnvironment: request.targetEnvironment,
    rollOutDateStartTime: request.rollOutDateStartTime,
    rollOutDateEndTime: request.rollOutDateEndTime,
    startDate: request.startDate,
    endDate: request.endDate,
    downTimeSchedule: request.downTimeSchedule,
    prevStatusId: request.prevStatusId,
    submittedEndTime: request.submittedEndTime,
    planningEndTime: request.planningEndTime,
    approvalEndTime: request.approvalEndTime,
    implementationEndTime: request.implementationEndTime,
    buildEndTime: request.buildEndTime,
    testingEndTime: request.testingEndTime,
    deploymentEndTime: request.deploymentEndTime,
    reviewEndTime: request.reviewEndTime,
    closeEndTime: request.closeEndTime,
    backToPlanning: request.shouldGoBackToPlanning,
    rating: request.feedbackRating,
    responseDueViolated: request.responseDueViolated,
    firstResponseTime: request.firstResponseTime,
    linkAssetIds: transformLinkAssetIds(request),
    projectStatus: request.projectStatus,
    ...(request.fieldValueDetails || {}),
    callFrom: request.callFrom,
    callType: request.callType,
    callTime: request.callTime,
    transitionModelId: request.transitionModelId,
    mergedRequest: request.mergedRequest,
    parentRequestId: request.parentRequestId,
    parentRequestName: request.parentRequestName,
    showApprovalTab: request.containArchiveApproval,
  }
}

export function transformTicketForServer(request) {
  const transformedProps = [
    'title',
    'watcher',
    'fieldValueDetails',
    'requesterData',
    'escalationLevel',
    'hasSecondaryTickets',
    'approvalStatus',
    'flotoScheduler',
    'linkAssetIds',
    'nextFireTime',
    'lastFiredTime',
  ]
  const obj = {
    ...(request.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...request.fieldValueDetails,
          },
        }
      : {}),
    ...(request.title ? { subject: request.title } : {}),
    ...(request.linkAssetIds
      ? { linkAssetIds: transformLinkAssetIdsForServer(request) }
      : {}),
  }
  // if ('watcher' in request) {
  //   obj.watcher = transformWatcherForServer(request.watcher)
  // }
  Object.keys(request).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = request[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: request[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = request[paramName]
    }
  })
  if ('description' in request) {
    obj.description = request.description || ''
  }
  if ('knownError' in request) {
    obj.knownError =
      request.knownError === 'true' || request.knownError === true
  }
  return obj
}

export const transformFeedbackForServer = (feedback) => {
  const obj = {
    ...(feedback.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...feedback.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(feedback).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = feedback[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: feedback[paramName],
        }
      }
    } else {
      obj[paramName] = feedback[paramName]
    }
  })
  return obj
}

export const transformTicketConversionForServer = (ticket) => {
  return {
    conversionType: ticket.conversionType.toUpperCase(),
    serviceCatalogId: ticket.serviceCatalogId,
    overwriteExistingData: ticket.overwriteExistingData,
    removePreviousTasks: ticket.removePreviousTasks,
    removeRelations: ticket.removeRelations,
    removeApprovalDetails: ticket.removeApprovalDetails,
    requestPatchMap: transformTicketForServer(ticket.requestRest),
  }
}

export function transformSolutionForServer(solution) {
  return {
    solution: solution.solution,
    solutionFileAttachments: solution.solutionFileAttachments,
  }
}

import { generateId } from '@utils/id'
import Constants from '@constants'
import {
  transformQualification,
  transformQualificationForServer,
} from './qualification'

export function transformDashlet(dashlet) {
  return {
    id: dashlet.dashLets,
    h: parseInt(dashlet.attributes.h, 10),
    w: parseInt(dashlet.attributes.w, 10),
    x: parseInt(dashlet.attributes.x, 10),
    y: parseInt(dashlet.attributes.y, 10),
    i: dashlet.attributes.guid || generateId(),
    type: dashlet.type,
    visulizationType: (dashlet.visualizationType || '').toLowerCase(),
    order: dashlet.dashLetsOrder,
    shortCutType: dashlet.attributes.shortCutType,
  }
}

export function transformDashletForServer(dashlet) {
  return {
    dashLets: dashlet.id,
    dashletsSize: dashlet.size,
    attributes: {
      h: `${dashlet.h}`,
      w: `${dashlet.w}`,
      x: `${dashlet.x}`,
      y: `${dashlet.y}`,
      guid: `${dashlet.i}`,
      ...(dashlet.shortCutType
        ? {
            shortCutType: dashlet.shortCutType,
          }
        : {}),
    },
    type: dashlet.type,
    visualizationType: dashlet.visulizationType,
  }
}

export const transformDashletDetail = (dashlet) => {
  return {
    ...(dashlet.dashletDetail
      ? {
          assetTypeId: dashlet.dashletDetail.assetTypeId,
          serviceCatalogId: dashlet.dashletDetail.serviceCatalogId,
          patchType: dashlet.dashletDetail.patchType,
          // co-relation report fields
          coRelatedAssetTypeId: dashlet.dashletDetail.coRelatedAssetTypeId,
          coRelatedPatchType: dashlet.dashletDetail.coRelatedPatchType,
          coRelatedModuleName: dashlet.dashletDetail.coRelatedModel,
          coRelatedQualifications: transformQualification(
            dashlet.dashletDetail.coRelatedQualification
          ),
          remoteDeploymentType: dashlet.dashletDetail.remoteDeploymentType,
        }
      : {}),
  }
}

export const transformDashletDetailForServer = (dashlet) => {
  const dashletDetail = {
    ...(dashlet.assetTypeId &&
    [
      Constants.ASSET,
      Constants.ASSET_HARDWARE,
      Constants.ASSET_SOFTWARE,
      Constants.ASSET_NON_IT,
      Constants.ASSET_CONSUMABLE,
    ].indexOf(dashlet.moduleName) >= 0
      ? {
          type: 'AssetReportDetailRest',
          assetTypeId: dashlet.assetTypeId,
          // co-relation report fields
          coRelatedAssetTypeId: dashlet.coRelatedAssetTypeId,
          coRelatedModel: dashlet.coRelatedModuleName,
          coRelatedQualification: transformQualificationForServer(
            dashlet.coRelatedQualifications
          ),
        }
      : {}),
    ...(dashlet.serviceCatalogId &&
    dashlet.moduleName === Constants.SERVICE_CATALOG
      ? {
          type: 'ServiceCatalogReportDetailRest',
          serviceCatalogId: dashlet.serviceCatalogId,
        }
      : {}),
    ...(dashlet.patchType && dashlet.moduleName === Constants.PATCH
      ? {
          type: 'PatchReportDetailRest',
          patchType: dashlet.patchType,
          // co-relation report fields
          coRelatedPatchType: dashlet.coRelatedPatchType,
          coRelatedModel: dashlet.coRelatedModuleName,
          coRelatedQualification: transformQualificationForServer(
            dashlet.coRelatedQualifications
          ),
        }
      : {}),
    ...(dashlet.remoteDeploymentType &&
    dashlet.moduleName === Constants.REMOTE_DEPLOYMENT
      ? {
          type: 'RemoteDeploymentReportDetailRest',
          remoteDeploymentType: dashlet.remoteDeploymentType,
        }
      : {}),
  }
  if (Object.keys(dashletDetail).length > 0) {
    return {
      dashletDetail,
    }
  }
  return {}
}

export function transformDashboard(dashboard) {
  return {
    id: dashboard.id,
    name: dashboard.name,
    startDate: dashboard.startDate,
    endDate: dashboard.endDate,
    timeType: dashboard.timeScale,
    dashlets: (dashboard.dashletsSet || []).map(transformDashlet),
    canEdit: dashboard.updatebleOob,
    canDelete: dashboard.deleteableOob,
    autorefreshMin: dashboard.autorefreshMin,
    userIds: dashboard.userIds,
    groupIds: dashboard.groupIds,
    dashboardAccessLevel: dashboard.dashboardAccessLevel,
  }
}

export function transformManagedDashboard(dashboard) {
  return {
    id: dashboard.id,
    name: dashboard.name,
    startDate: dashboard.startDate,
    endDate: dashboard.endDate,
    timeType: dashboard.timeScale,
    dashlets: (dashboard.dashletsSet || []).map(transformDashlet),
    userIds: dashboard.userIds,
    groupIds: dashboard.groupIds,
    dashboardAccessLevel: dashboard.dashboardAccessLevel,
    enabled: !dashboard.disabled,
    canEdit: dashboard.updatebleOob,
    canDelete: dashboard.deleteableOob,
  }
}
export function transformManagedDashboardList(dashboard) {
  return {
    ...dashboard,
    text: dashboard.name,
    key: dashboard.id,
    canDelete: dashboard.deleteableOob,
  }
}

export function transformManagedDashboardForServer(dashboard) {
  return {
    id: dashboard.id,
    name: dashboard.name,
    ...(dashboard.dashboardAccessLevel !== 'restricted'
      ? {
          userIds: [],
          groupIds: [],
        }
      : {
          userIds: dashboard.userIds,
          groupIds: dashboard.groupIds,
        }),
    dashboardAccessLevel: dashboard.dashboardAccessLevel,
    // disabled: dashboard.disabled,
  }
}

export function transformDashboardForServer(dashboard) {
  return {
    id: dashboard.id,
    name: dashboard.name,
    startDate: dashboard.startDate,
    endDate: dashboard.endDate,
    timeScale: dashboard.timeType,
    autorefreshMin: dashboard.autorefreshMin,
    ...(dashboard.dashboardAccessLevel !== 'restricted'
      ? {
          userIds: [],
          groupIds: [],
        }
      : {
          userIds: dashboard.userIds,
          groupIds: dashboard.groupIds,
        }),
    dashboardAccessLevel: dashboard.dashboardAccessLevel,
  }
}

export function transformAllDashletsForServer(dashlets) {
  return {
    dashletsSet: dashlets.map(transformDashletForServer),
  }
}

export function transformKpi(kpi) {
  return {
    id: kpi.id,
    name: kpi.name,
    userIds: kpi.userIds,
    groupIds: kpi.groupIds,
    enableCustomQuery: kpi.enableCustomQuery,
    customQuery: kpi.customQuery,
    description: kpi.description,
    qualifications: transformQualification(kpi.qualification),
    serverQualifications: kpi.qual,
    dateFilter: kpi.dateFilterProp,
    moduleName: kpi.model,
    orginalModuleName: kpi.orginalModel,
    ...(kpi.kpiThreshold && kpi.kpiThreshold.length > 0
      ? {
          colorConfig: kpi.kpiThreshold.map((i) => ({
            thresholdValue: i.thresholdValue,
            color: i.color,
            label: i.label,
            guid: generateId(),
          })),
        }
      : {}),
    ...transformDashletDetail(kpi),
    canEdit: kpi.updatebleOob,
    canDelete: kpi.deleteableOob,
  }
}

export function transformKpiForServer(kpi) {
  return {
    id: kpi.id,
    model: kpi.moduleName,
    name: kpi.name,
    userIds: kpi.userIds,
    groupIds: kpi.groupIds,
    enableCustomQuery: kpi.enableCustomQuery,
    customQuery: kpi.customQuery,
    description: kpi.description,
    qualification: transformQualificationForServer(kpi.qualifications),
    dateFilterProp: kpi.dateFilter,
    ...(kpi.colorConfig
      ? {
          kpiThreshold:
            kpi.colorConfig.length > 0
              ? kpi.colorConfig.map((i) => ({
                  thresholdValue: i.thresholdValue,
                  color: i.color,
                  label: i.label,
                }))
              : [],
        }
      : {}),
    ...transformDashletDetailForServer(kpi),
  }
}

export function transformChart(widget) {
  return {
    // xAxisColumn: widget.xPropKey,
    ...transformDashletDetail(widget),
    ...(widget.yPropKey
      ? {
          yAxisFunction: widget.yPropKey.aggregatedFunction,
          yAxisColumn: widget.yPropKey.propKey,
          columnValues: widget.yPropKey.selectedValues,
        }
      : {}),
    ...(widget.xPropKey
      ? {
          xAxisColumn: widget.xPropKey.propKey,
          xColumnValues: widget.xPropKey.selectedValues,
        }
      : {}),
    visulizationType: (widget.visualizationType || '').toLowerCase(),
    top: widget.top,
    ...(widget.sort
      ? {
          topType: widget.sort.sort,
          sortByColumnValue: widget.sort.columnValue,
          yAxisColumn: widget.sort.columnKey,
        }
      : { topType: 'none' }),
  }
}

export function transformWidget(widget) {
  return {
    id: widget.id,
    name: widget.name,
    description: widget.description,
    userIds: widget.userIds,
    groupIds: widget.groupIds,
    enableCustomQuery: widget.enableCustomQuery,
    customQuery: widget.customQuery,
    qualifications: transformQualification(widget.qualification),
    serverQualifications: widget.qual,
    dateFilter: widget.dateFilterProp,
    top: widget.topCount,
    moduleName: widget.model,
    orginalModuleName: widget.orginalModel,
    ...transformChart(widget),
    canEdit: widget.updatebleOob,
    canDelete: widget.deleteableOob,
  }
}

export function transformChartForServer(widget) {
  return {
    // xPropKey: widget.xAxisColumn,
    yPropKey: {
      aggregatedFunction: widget.yAxisFunction,
      propKey: widget.yAxisColumn,
      selectedValues: widget.columnValues,
    },
    xPropKey: {
      propKey: widget.xAxisColumn,
      selectedValues: widget.xColumnValues,
    },
    visualizationType: widget.visulizationType,
    top: widget.top,
    sort: {
      sort: widget.topType,
      columnValue: widget.sortByColumnValue,
      columnKey: widget.yAxisColumn,
    },
  }
}

export function transformWidgetForServer(widget) {
  return {
    id: widget.id,
    model: widget.moduleName,
    name: widget.name,
    userIds: widget.userIds,
    groupIds: widget.groupIds,
    enableCustomQuery: widget.enableCustomQuery,
    customQuery: widget.customQuery,
    description: widget.description,
    qualification: transformQualificationForServer(widget.qualifications),
    dateFilterProp: widget.dateFilter,
    ...transformDashletDetailForServer(widget),
    ...transformChartForServer(widget),
  }
}
export function transformCreateDashboard(dashboard) {
  return {
    id: dashboard.id,
    name: dashboard.name,
    userIds: dashboard.userIds,
    groupIds: dashboard.groupIds,
    dashboardAccessLevel: dashboard.dashboardAccessLevel,
  }
}

export function transformCreateDashboardForServer(dashboard) {
  return {
    name: dashboard.name,
    userIds: dashboard.userIds,
    groupIds: dashboard.groupIds,
    dashboardAccessLevel: dashboard.dashboardAccessLevel,
  }
}

export function transformWidgetTable(table) {
  const groupList = table.groupList
  const chartData = {
    noData: false,
  }
  if (
    table === null ||
    groupList === null ||
    !(((groupList || [])[0] || {}).columnList || []).length
  ) {
    chartData.noData = true
  }
  if ((groupList || []).length === 0) {
    return chartData
  }
  chartData.xAxis = {
    name: table.groupTitle,
    keys: groupList.map((c) => c.title),
  }
  chartData.yAxis = {
    name: ((((groupList || [])[0] || {}).columnList || [])[0] || {})
      .parentTitle,
    datasets: groupList[0].columnList.map((c) => {
      const values = []
      const qualifications = []
      groupList.forEach((group) => {
        // for single group found the column
        const column = group.columnList.find(
          (_t) => _t.columnKey === c.columnKey
        )
        if (column) {
          values.push(column.data[0] ? +column.data[0] : column.data[0])
          qualifications.push(column.qual)
        }
      })
      return {
        title: c.title,
        values,
        qualifications,
      }
    }),
  }
  if (chartData.yAxis.datasets.length === 1) {
    // check pie chart for no data only
    const data = chartData.yAxis.datasets[0].values

    if (data.filter((x) => x > 0).length === 0) {
      chartData.noData = true
    }
  }
  return chartData
}

export function transformShortcut(shortcut) {
  return {
    id: shortcut.id,
    name: shortcut.name,
    description: shortcut.description,
    userIds: shortcut.userIds,
    groupIds: shortcut.groupIds,
    enableCustomQuery: shortcut.enableCustomQuery,
    customQuery: shortcut.customQuery,
    serverQualifications: shortcut.qual,
    moduleName: shortcut.model,
    orginalModuleName: shortcut.orginalModel,
    shortCutType: shortcut.shortCutType,
    canEdit: shortcut.updatebleOob,
    canDelete: shortcut.deleteableOob,
  }
}

export function transformShortcutForServer(shortcut) {
  return {
    id: shortcut.id,
    model: shortcut.moduleName,
    name: shortcut.name,
    userIds: shortcut.userIds,
    groupIds: shortcut.groupIds,
    enableCustomQuery: shortcut.enableCustomQuery,
    customQuery: shortcut.customQuery,
    description: shortcut.description,
  }
}

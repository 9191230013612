import {
  transformQualificationForServer,
  transformQualification,
} from './qualification'

export function transformUserGroup(group) {
  // @TODO transform user group here
  return {
    ...group,
    disabled: group.disabled,
    archived: group.removed,
  }
}

export function transformUserGroupForList(group) {
  return {
    id: group.id,
    name: group.name,
    description: group.description,
    users: group.users,
    canDelete: group.deleteableOob,
    archived: group.removed,
  }
}

export function transformGroupForCrud(group) {
  return {
    id: group.id,
    name: group.name,
    description: group.description,
    businessHour: group.bizHourId,
    users: group.users,
    autoAssignment: group.autoAssignment,
    qualifications: transformQualification(group.qualification),
  }
}

export function transformGroupForServer(group) {
  return {
    name: group.name,
    description: group.description,
    bizHourId: group.businessHour,
    users: group.users,
    autoAssignment: group.autoAssignment,
    ...(group.qualifications
      ? { qualification: transformQualificationForServer(group.qualifications) }
      : {}),
  }
}
